import AWS, { S3 } from "aws-sdk";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  // other global configurations if necessary
});

// Function to initialize the S3 client
export const initializeS3Client = () => {
  const s3 = new S3();
  return s3;
};

export const resizeAndCompressImage = (
  file,
  maxWidth,
  maxHeight,
  quality,
  useWebP = false
) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Resize the image
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Compress the image and convert to WebP if required
      const mimeType = useWebP ? "image/webp" : file.type;
      canvas.toBlob(resolve, mimeType, quality);
    };

    img.onerror = reject;
  });
};

export const uploadFile = async (s3, file) => {
  const params = {
    Bucket: "togather-media-bucket",
    Key: file.name,
    ContentType: file.type,
    Body: file,
    // other necessary S3 configuration
  };

  const upload = s3.upload(params);

  upload.on("httpUploadProgress", (evt) => {
    console.log("Upload Progress:", evt.loaded, "/", evt.total);
  });

  await upload.promise();

  return params.Key;
};

export const handleUpload = async ({
  images,
  profile,
  project,
  profileImage,
}) => {
  const s3 = await initializeS3Client();
  const uploadPromises = images.map(async (image) => {
    if (image.type === "video") {
      return image.content;
    }
    try {
      const response = await fetch(image.content);
      const blob = await response.blob();

      const resizedBlob = await resizeAndCompressImage(
        blob,
        2048, // Width
        2048, // Height
        0.9, // Quality (slightly reduced for better file size management)
        true // Convert to WebP
      );

      // Change the file extension to .webp if converting to WebP
      const filename = image.id.replace(/\.\w+$/, "") + ".webp";
      let name;

      if (profile) {
        name = `profiles/${profile}/${filename}`;
      } else if (project) {
        name = `projects/${project}/${filename}`;
      } else if (profileImage) {
        name = `profileImages/${profileImage}/${filename}`;
      }
      const file = new File([resizedBlob], name, { type: "image/webp" });

      // Upload the file to S3
      const key = await uploadFile(s3, file);

      return key;
    } catch (error) {
      console.error("Error uploading image:", image.id, error);
      // Handle error (e.g., return null or error info)
      return null;
    }
  });

  const media = await Promise.all(uploadPromises);
  return media.filter((url) => url !== null); // Filter out any nulls from errors
};

export const deleteFromS3 = async (selectedImages) => {
  const s3 = await initializeS3Client();
  const deletePromises = selectedImages.map((image) => {
    const key = image.content.split("/").pop(); // Extract the key from the URL
    const params = {
      Bucket: "togather-media-bucket",
      Key: key,
    };
    return s3.deleteObject(params).promise();
  });

  await Promise.all(deletePromises);
};

export const deleteProfileImageFromS3 = async (selectedImage) => {
  try {
    const s3 = await initializeS3Client();
    const params = {
      Bucket: "togather-media-bucket",
      Key: selectedImage,
    };

    await s3.deleteObject(params).promise();
  } catch (error) {
    console.log(`Error deleting image ${selectedImage}:`, error);
  }
};
