import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Home from "./components/Home";
import Authentication from "./components/Authentication";
import ProjectPage from "./components/ProjectPage";
import Chat from "./components/Chat";
import Settings from "./components/Settings";
import Profile from "./components/Profile";
import MyProjects from "./components/MyProjects";
import CreateProject from "./components/CreateProject";
import PrivateRoute from "./components/PrivateRoute";
import Favorites from "./components/Favorites";
import Submissions from "./components/Submissions";
import ViewSubmissions from "./components/ViewSubmissions";
import EditProject from "./components/EditProject";
import TopAppBar from "./components/TopAppBar";
import { APIProvider } from "@vis.gl/react-google-maps";
import { UserProvider } from "./components/UserContext";

const App = () => {
  const location = useLocation(); // Get the current location
  const isLoginPage = location.pathname === "/login"; // Check if it's the login page

  return (
    <ThemeProvider theme={theme}>
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <UserProvider>
          <div className="App">
            {isLoginPage ? null : <TopAppBar />}

            <Routes>
              <Route path="/login" element={<Authentication />} />
              <Route path="/" element={<Home />} />
              <Route path="/project/:projectId" element={<ProjectPage />} />
              <Route
                path="/projects"
                element={
                  <PrivateRoute>
                    <MyProjects />
                  </PrivateRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  <PrivateRoute>
                    <Chat />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/favorites"
                element={
                  <PrivateRoute>
                    <Favorites />
                  </PrivateRoute>
                }
              />
              <Route
                path="/submissions/:projectId"
                element={
                  <PrivateRoute>
                    <Submissions />
                  </PrivateRoute>
                }
              />
              <Route
                path="/submissions/role/:roleId"
                element={
                  <PrivateRoute>
                    <ViewSubmissions />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile/:userId"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route
                path="/createproject"
                element={
                  <PrivateRoute>
                    <CreateProject />
                  </PrivateRoute>
                }
              />
              <Route
                path="/editproject/:projectId"
                element={
                  <PrivateRoute>
                    <EditProject />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </UserProvider>
      </APIProvider>
    </ThemeProvider>
  );
};

export default App;
