import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Lightbox from "yet-another-react-lightbox";
import Button from "@mui/material/Button";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SortableTool from "./Sortable";
import { handleUpload, deleteFromS3 } from "./imageUtils";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import MessageIcon from "@mui/icons-material/Message";
import { useNavigate } from "react-router-dom";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

const Profile = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [value, setValue] = useState(0);
  const [index, setIndex] = useState(-1);
  const [showOptions, setShowOptions] = useState(true);
  const [user, setUser] = useState({});
  const [images, setImages] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [following, setFollowing] = useState(false);
  const [open, setOpen] = useState(false);
  const [portfolioImages, setPortfolioImages] = useState([]);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleImageClick = (index) => {
    setIndex(index);
  };

  const transformArrayLightBox = (array) => {
    return array.map((item, index) => ({
      src: cdn + item,
    }));
  };

  const transformArrayImageList = (array) => {
    return array.map((item, index) => ({
      img: cdn + item,
    }));
  };

  const handleImages = (data) => {
    setPortfolioImages(data);
  };

  const handleEditPortfolio = async () => {
    try {
      const mediaUrls = await handleUpload({
        images: portfolioImages,
        profile: localStorage.getItem("userId"),
      }).catch(console.error);
      const userData = {
        email: localStorage.getItem("userEmail"),
        portfolioMedia: mediaUrls,
      };

      try {
        const response = await fetch(
          "https://6rv1ygx9qe.execute-api.us-east-1.amazonaws.com/prod/users/portfolio",
          {
            headers: {
              Authorization: `${localStorage.getItem("accessToken")}`,
            },
            method: "PUT",
            body: JSON.stringify(userData),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        console.log("Update portfolio was successful:", result);
        setOpen(false);
      } catch (error) {
        console.error("Update failed:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select("*")
        .eq("id", userId);

      if (data) {
        setUser(data[0]);
        if (data[0].media) {
          setImages(transformArrayImageList(data[0].media));
          setLightBox(transformArrayLightBox(data[0].media));
        }
      }
    };
    getUser();
    checkUser();
  }, [userId]);

  const checkUser = async () => {
    if (currentUser && currentUser.sub === userId) {
      setShowOptions(false);
    } else if (currentUser && currentUser.sub !== userId) {
      const { data, error } = await supabase
        .from("Followers")
        .select("*")
        .eq("follower_id", currentUser.sub) // Replace followerId with the actual follower's user ID
        .eq("following_id", userId); // Replace followingId with the actual followed user's ID

      if (error) console.error("Error checking follow status:", error);
      else if (data.length > 0) setFollowing(true);
      else setFollowing(false);
    } else {
      setShowOptions(false);
    }
  };

  const handleUnfollow = async () => {
    setFollowing(false);
    const { data, error } = await supabase
      .from("Followers")
      .delete()
      .eq("follower_id", currentUser.sub)
      .eq("following_id", userId);

    if (error) {
      setFollowing(true);
      console.error("Error removing follow relationship:", error);
    }
  };

  const handleFollow = async () => {
    setFollowing(true);
    const { data, error } = await supabase
      .from("Followers")
      .insert([{ follower_id: currentUser.sub, following_id: userId }]);

    if (error) {
      setFollowing(false);
      console.error("Error adding follow relationship:", error);
    }
  };

  const handleMessage = () => {
    navigate(`/chat`, { state: { recipientId: userId } });
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins"
        sx={{
          paddingBottom: "3rem",
          paddingTop: "2rem",
        }}
      >
        {" "}
        <Container
          sx={{
            padding: "20px 0px 20px 0px !important",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            borderRadius: "10px",
            height: "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "400px",
            backgroundColor: "#ffffff",
          }}
        >
          <Avatar
            sx={{ width: 150, height: 150 }}
            alt={user.userName}
            src={user.profileImageUrl ? cdn + user.profileImageUrl : null}
          />
          <Typography
            variant="h5"
            sx={{
              marginTop: "20px",
              fontWeight: 300,
            }}
          >
            {user && user.first_name && `${user.first_name} ${user.last_name}`}
          </Typography>
          {user.role && (
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "20px",
                fontWeight: 300,
              }}
            >
              {user.role}
            </Typography>
          )}

          {user.city && (
            <Typography
              variant="caption"
              sx={{
                marginTop: "10px",
                fontWeight: 300,
              }}
            >
              {user.city}
            </Typography>
          )}

          {showOptions && (
            <Box sx={{ display: "flex", gap: "20px" }}>
              {following ? (
                <Button
                  onClick={handleUnfollow}
                  sx={{
                    backgroundColor: "#9f7fe3",
                    ":hover": {
                      bgcolor: "#9f7fe3d8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                >
                  Following
                </Button>
              ) : (
                <Button
                  onClick={handleFollow}
                  sx={{
                    backgroundColor: "#9f7fe3",
                    ":hover": {
                      bgcolor: "#9f7fe3d8",
                    },
                    borderRadius: "22px",
                    marginTop: "20px",
                  }}
                  variant="contained"
                  startIcon={<PersonAddAltIcon />}
                >
                  Follow
                </Button>
              )}

              <Button
                onClick={handleMessage}
                sx={{
                  backgroundColor: "#9f7fe3",
                  ":hover": {
                    bgcolor: "#9f7fe3d8",
                  },
                  borderRadius: "22px",
                  marginTop: "20px",
                }}
                variant="contained"
                startIcon={<MessageIcon />}
              >
                Message
              </Button>
            </Box>
          )}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Edit Portfolio</DialogTitle>
            <DialogContent>
              <DialogContentText>Media</DialogContentText>
              <SortableTool
                sendImages={handleImages}
                initialData={user.profileMedia}
                deleteFromS3={deleteFromS3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleEditPortfolio}>Apply</Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Container sx={{ paddingBottom: "40px", marginTop: "60px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Typography
              variant="h4"
              sx={{
                textAlign: "left",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              {t("portfolio")}
            </Typography>
            {!showOptions && (
              <Button
                onClick={handleClickOpen}
                sx={{
                  backgroundColor: "#9f7fe3",
                  ":hover": {
                    bgcolor: "#9f7fe3d8",
                  },
                  borderRadius: "22px",
                }}
                variant="contained"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            )}
          </Box>

          <ImageList variant="masonry" cols={3} gap={4}>
            {images &&
              images.length > 0 &&
              images.map((item, index) => (
                <ImageListItem
                  key={index}
                  onClick={() => handleImageClick(index)}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.img}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
          </ImageList>

          {lightBox && lightBox.length > 0 && (
            <Lightbox
              slides={lightBox}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              // enable optional lightbox plugins
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
