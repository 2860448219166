import React, { useEffect, useContext } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {
  handleUpload,
  resizeAndCompressImage,
  deleteProfileImageFromS3,
} from "./imageUtils";
import { supabase } from "./SupabaseClient";
import { useTranslation } from "react-i18next";
import { UserContext } from "./UserContext"; // Adjust the import path accordingly
import { useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

// Define the style configuration
const configurations = {
  theme: "light",
  variables: {
    backgroundColor: "#ffffff",
    primaryTextColor: "#000000",
    secondaryTextColor: "#9ca3af",
    primaryColor: "#c88bc4",
    tabBackgroundColor: "#111827",
    tabHoverBackgroundColor: "#374151",
    tabBorderColor: "#ffffff",
    tabHoverBorderColor: "#7dd3fc",
    buttonBackgroundColor: "#9f7fe3",
    buttonTextColor: "#ffffff",
    buttonHoverTextColor: "#ffffff",
    buttonHoverBackgroundColor: "#9f7fe3d8",
    inputBackgroundColor: "#f2f2f2",
    inputBorderColor: "#ffffff",
    buttonDisabledBackgroundColor: "#9f7fe3",
    buttonDisabledTextColor: "#ffffff",
    inputPlaceholderColor: "#000000",
    borderRadius: "8px",
  },
};

// Stringify and URL encode the style configuration
const url_encoded_config = encodeURIComponent(JSON.stringify(configurations));

const Settings = () => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [user, setUser] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [city, setCity] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { updateUser } = useContext(UserContext);
  const [active, setActive] = useState("general");
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [accountLinkCreatePending, setAccountLinkCreatePending] =
    useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const [viewMode, setViewMode] = useState("menu"); // New state for view mode
  const isMobile = useMediaQuery("(max-width:600px)");
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [flowURI, setFlowURI] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select("*")
        .eq("id", currentUser.sub);

      if (data) {
        setUser(data[0]);
        setFirstName(data[0].first_name);
        setLastName(data[0].last_name);
        if (data[0].city) {
          setCity(data[0].city);
        }
        if (data[0].profile_picture) {
          setAvatarSrc(cdn + data[0].profile_picture);
        }
      }
    };
    getUser();
  }, []);

  const cancelUpload = () => {
    setAvatarSrc(cdn + user.profileImageUrl);
    setSelectedFile(null);
  };
  const handleButtonClick = (section) => {
    setActive(section);
    if (isMobile) {
      setViewMode("detail"); // Switch to detail view for mobile
    }
  };

  const goBackToMenu = () => {
    setViewMode("menu"); // Go back to the button menu
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Handle file selection
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const resizedBlob = await resizeAndCompressImage(
      file,
      2048, // Width
      2048, // Height
      0.9, // Quality (slightly reduced for better file size management)
      true
    ); // maxWidth, maxHeight, quality
    const localUrl = URL.createObjectURL(resizedBlob);
    setAvatarSrc(localUrl);
    const imageObject = {
      content: URL.createObjectURL(file), // This is the file content
      id: file.name, // Unique identifier for the file
    };

    // Update your state with this new object
    setSelectedFile(imageObject);
  };

  const handleImageUpdate = async () => {
    const newImageUrl = await handleUpload({
      images: [selectedFile],
      profileImage: currentUser.sub,
    });
    if (user.profile_picture) {
      await deleteProfileImageFromS3(user.profile_picture);
    }

    setSelectedFile(null);

    const { error: updateError } = await supabase
      .from("Users")
      .update({ profile_picture: newImageUrl[0] })
      .eq("id", currentUser.sub);

    if (updateError) {
      throw updateError;
    }

    // Clear the selected file and update the UI

    setSelectedFile(null);
    setAvatarSrc(cdn + newImageUrl[0]);
    updateUser({ profile_picture: newImageUrl[0] });
  };

  const handleSave = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({
        first_name: firstName,
        last_name: lastName,
        city: city ? city : null,
      })
      .eq("id", currentUser.sub);
  };

  const createPaymentAccount = async () => {
    setLoading(true);
    setError(null);

    setAccountCreatePending(true);
    setError(false);
    fetch(
      "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account",
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then(async (json) => {
        setAccountCreatePending(false);

        const { accountId, error } = json;

        if (accountId) {
          setConnectedAccountId(accountId);
          await updateStripeId(accountId);
          setLoading(false);
        }

        if (error) {
          setError(true);
        }
      });
  };

  const handleAccountSetup = async () => {
    setAccountLinkCreatePending(true);
    setError(false);
    fetch(
      "https://54rr0rbk9h.execute-api.us-east-1.amazonaws.com/prod/stripe/create-account-link",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accountId: connectedAccountId,
        }),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setAccountLinkCreatePending(false);

        const { url, error } = json;
        if (url) {
          window.location.href = url;
        }

        if (error) {
          setError(true);
        }
      });
  };

  const updateStripeId = async (id) => {
    const { error } = await supabase
      .from("Users")
      .update({ stripe_id: id })
      .eq("id", currentUser.sub);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: "#ffffff" }}>
      <Box
        className="custom-margins settings"
        sx={{
          paddingBottom: "3rem",
        }}
      >
        {" "}
        <Grid container spacing={4}>
          {(viewMode === "menu" || !isMobile) && (
            <Grid item xs={12} md={3}>
              <Container
                sx={{
                  padding: "20px",
                  marginTop: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <button
                  className="settings-btn"
                  style={{
                    backgroundColor: active === "general" ? "#9f7fe3" : "white",
                    color: active === "general" ? "#ffffff" : "#9f7fe3",
                  }}
                  onClick={() => handleButtonClick("general")}
                >
                  {t("general")}
                </button>
                <button
                  className="settings-btn"
                  style={{
                    backgroundColor:
                      active === "payments" ? "#9f7fe3" : "white",
                    color: active === "payments" ? "#ffffff" : "#9f7fe3",
                  }}
                  onClick={() => handleButtonClick("payments")}
                >
                  {t("payments")}
                </button>
              </Container>
            </Grid>
          )}

          {/* Render detail view if 'detail' mode is active */}
          {viewMode === "detail" && isMobile && (
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <IconButton aria-label="back" onClick={goBackToMenu}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
          )}
          {viewMode === "detail" || !isMobile ? (
            <Grid item xs={12} md={9}>
              <Container
                sx={{
                  padding: "30px !important",
                  marginTop: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "540px",
                  position: "relative",
                }}
              >
                {active === "general" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Avatar
                      sx={{ width: "150px", height: "150px" }}
                      alt={firstName}
                      src={avatarSrc}
                    />

                    {selectedFile ? (
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Button
                          sx={{
                            backgroundColor: "#9f7fe3",
                            ":hover": {
                              bgcolor: "#9f7fe3d8",
                            },
                          }}
                          size="small"
                          variant="contained"
                          onClick={handleImageUpdate}
                        >
                          {t("save")}
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "#9f7fe3",
                            ":hover": {
                              bgcolor: "#9f7fe3d8",
                            },
                          }}
                          size="small"
                          variant="contained"
                          onClick={cancelUpload}
                        >
                          {t("cancel")}
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        component="label"
                        sx={{ width: "140px", color: "#564a92" }}
                        variant="text"
                      >
                        {t("uploadPhoto")}
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange}
                          accept="image/*"
                        />
                      </Button>
                    )}
                    <Grid container spacing={3} sx={{ maxWidth: "500px" }}>
                      <Grid item xs={3}>
                        <div className="title">{t("firstName")}</div>
                      </Grid>
                      <Grid item xs={9} sx={{ textAlign: "left" }}>
                        {" "}
                        <TextField
                          sx={{ width: "100%" }}
                          value={firstName || ""}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <div className="title">{t("lastName")}</div>
                      </Grid>
                      <Grid item xs={9} sx={{ textAlign: "left" }}>
                        {" "}
                        <TextField
                          sx={{ width: "100%" }}
                          value={lastName || ""}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {" "}
                        <div className="title">{t("city")}</div>
                      </Grid>
                      <Grid item xs={9} sx={{ textAlign: "left" }}>
                        {" "}
                        <TextField
                          sx={{ width: "100%" }}
                          size="small"
                          variant="outlined"
                          placeholder="Enter City"
                          value={city || ""}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={9} sx={{ textAlign: "right" }}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleSave();
                          }}
                        >
                          {t("save")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {active === "payments" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px",
                      height: "100%",
                    }}
                  >
                    <button
                      className="stripe-btn"
                      onClick={() => createPaymentAccount()}
                    >
                      Create an account
                    </button>
                    {connectedAccountId && !accountLinkCreatePending && (
                      <button onClick={() => handleAccountSetup()}>
                        Add information
                      </button>
                    )}
                  </Box>
                )}
              </Container>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default Settings;
