// theme.js or theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Epilogue", "Dela Gothic One", "sans-serif"].join(","),
    body1: {
      fontSize: "12px", // Default font size for body text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px", // Default font size for buttons
          // You can add more styles here if needed
        },
        sizeSmall: {
          fontSize: "12px", // Font size for small buttons
        },
        sizeLarge: {
          fontSize: "16px", // Font size for large buttons
        },
      },
    },
  },
});

export default theme;
