import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import ChatIcon from "@mui/icons-material/Chat";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import { checkUserSignedIn } from "./AuthUtils";
import { supabase } from "./SupabaseClient";
import Grid from "@mui/material/Grid";
import { UserContext } from "./UserContext";

const cdn = "https://d71lxjj3xpfs2.cloudfront.net/";

const defaultFilterOptions = createFilterOptions();

const TopAppBar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const isChatPage = location.pathname === "/chat"; // Check if it's the login page
  const [anchorNotifs, setAnchorNotifs] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const open = Boolean(anchorNotifs);
  const handleClick = (event) => {
    setAnchorNotifs(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorNotifs(null);
  };
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const { user, loading } = useContext(UserContext);

  const showOptions = dropdownOpen && inputValue.length > 0;

  const handleOptionSelected = (event, value) => {
    if (value) {
      // Assuming `value` has a property that determines the navigation path
      navigate(`/project/${value.id}`);
    }
  };

  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToChat = () => {
    navigate("/chat");
  };

  const navigateToSettings = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/settings");
  };

  const navigateToProfile = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(`/profile/${currentUser.sub}`);
  };

  const navigateToMyProjects = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/projects");
  };

  const navigateToMyFavorites = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/favorites");
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    localStorage.removeItem("user");
    navigate("/login");
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = localStorage.getItem("user") ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={navigateToProfile}>Profile</MenuItem>
      <MenuItem onClick={navigateToMyProjects}>Projects</MenuItem>
      <MenuItem onClick={navigateToMyFavorites}>Favorites</MenuItem>
      <MenuItem onClick={navigateToSettings}>My Account</MenuItem>
      <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = localStorage.getItem("user") ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <ChatIcon onClick={navigateToChat} />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ height: "100px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={4}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                display: { xs: "none", md: "flex" },
              }}
            >
              <img
                alt="togather text logo"
                onClick={navigateToHome}
                src="/images/togather_text_logo.svg"
                className="cursor"
              ></img>
            </Grid>
            <Grid
              item
              xs={2}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="togather logo"
                src="/images/togather_logo.svg"
                sx={{
                  mr: 2,
                  cursor: "pointer",
                }}
                onClick={navigateToHome}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              {/* <Autocomplete
                disablePortal
                freeSolo
                ListboxProps={{
                  style: {
                    maxHeight: 100,
                    fontFamily: "Epilogue",
                    fontSize: "12px",
                  },
                }}
                options={[...projects]}
                filterOptions={(options, params) => {
                  const { inputValue } = params;
                  if (!inputValue) {
                    return [];
                  }
                  return defaultFilterOptions(options, params);
                }}
                onChange={handleOptionSelected} // Add the onChange event handler
                getOptionLabel={(option) => option.projectName || ""}
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                sx={{
                  width: "200px",
                  marginLeft: "20px",
                  "& .MuiInputBase-root": {
                    fontFamily: "Epilogue",
                    fontSize: "12px",
                    height: "40px",
                    backgroundColor: "#F2F2F2",
                    width: "100%",
                    padding: "2px 4px 4px 12px",
                    borderRadius: showOptions ? "20px 20px 0 0" : "40px", // Conditional border-radius
                  },
                  "& .Mui-focused fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                  "& fieldset": {
                    borderWidth: "0px !important", // Adjust border-width when focused
                  },
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      {option.projectName}
                    </Box>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(renderInputParams) => (
                  <div
                    ref={renderInputParams.InputProps.ref}
                    style={{
                      alignItems: "center",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TextField
                      style={{ flex: 1 }}
                      InputProps={{
                        ...renderInputParams.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ color: "#9F7FE3" }}
                          >
                            {" "}
                            <Search />{" "}
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      inputProps={{
                        ...renderInputParams.inputProps,
                      }}
                      InputLabelProps={{ style: { display: "none" } }}
                    />
                  </div>
                )}
              /> */}
              <Box
                sx={{
                  display: { xs: "flex", md: "flex", paddingRight: "5px" },
                }}
              >
                {localStorage.getItem("user") && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    <IconButton
                      size="large"
                      aria-label="show new mails"
                      onClick={navigateToChat}
                      id="notif-button"
                      aria-controls={open ? "notif-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <Badge badgeContent={unreadCount} color="error">
                        <ChatIcon sx={{ color: "#333333" }} />
                      </Badge>
                    </IconButton>
                    <div>
                      <IconButton
                        size="large"
                        aria-label="show new notifications"
                        onClick={handleClick}
                      >
                        <Badge badgeContent={notificationsCount} color="error">
                          <NotificationsIcon sx={{ color: "#333333" }} />
                        </Badge>
                      </IconButton>
                      <Menu
                        id="notif-menu"
                        anchorEl={anchorNotifs}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {notifications &&
                          notifications.map((notification) => {
                            return (
                              <MenuItem onClick={handleClose}>
                                {notification.content}
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </div>
                  </Box>
                )}

                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="#ffffff"
                >
                  <Avatar
                    sx={{ maxWidth: "30px", maxHeight: "30px" }}
                    src={
                      user && user.profile_picture
                        ? cdn + user.profile_picture
                        : null
                    }
                  />
                </IconButton>
              </Box>
              <Box sx={{ display: { xs: "none", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </React.Fragment>
  );
};

export default TopAppBar;
