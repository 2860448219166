import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { v4 as uuidv4 } from "uuid";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css"; // Import quill styles
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SortableTool from "./Sortable";
import LinearProgress from "@mui/material/LinearProgress";
import { handleUpload } from "./imageUtils";
import IconButton from "@mui/material/IconButton";
import ReactPlayer from "react-player";
import LinkIcon from "@mui/icons-material/Link";
import { supabase } from "./SupabaseClient";
import axios from "axios";
import { useTranslation } from "react-i18next";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import GroupsIcon from "@mui/icons-material/Groups";
import Avatar from "@mui/material/Avatar";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { use } from "i18next";

// let Size = ReactQuill.Quill.import("attributors/style/size");
// Size.whitelist = [
//   "8px",
//   "10px",
//   "12px",
//   "14px",
//   "16px",
//   "18px",
//   "20px",
//   "22px",
//   "24px",
//   "26px",
//   "28px",
//   "30px",
//   "32px",
//   "34px",
//   "36px",
//   "38px",
//   "40px",
// ];
// ReactQuill.Quill.register(Size, true);

// const modules = {
//   toolbar: [
//     // ... (other toolbar options)
//     [{ size: Size.whitelist }],
//     [{ font: [] }], // Dropdown to select font
//     [{ size: ["small", "medium", "large", "huge"] }], // Dropdown to select font size
//     [{ align: [] }], // Dropdown with text align options
//     ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
//     [{ color: [] }, { background: [] }], // Dropdowns to select text color and background color
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ], // Lists and indentation
//     [{ direction: "rtl" }], // Text direction
//   ],
// };

const EditProject = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [step, setStep] = useState(1);
  const [roleOptions, setRoleOptions] = useState([]);
  const [openDialog, toggleOpen] = useState(false);
  const [openRoleDialog, toggleRoleOpen] = useState(false);
  const [projectDate, setProjectDate] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [dialogValue, setDialogValue] = useState({
    id: "",
    name: "",
  });
  const [userDialogValue, setUserDialogValue] = useState({
    id: "",
    name: "",
  });
  const [userRole, setUserRole] = useState(null);
  const [durationQuantity, setDurationQuantity] = useState("");
  const [duration, setDuration] = useState("Days");
  const [rolesArray, setRolesArray] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [description, setDescription] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);
  const [images, setImages] = useState([]);
  const [type, setType] = useState("collab");
  const [roleBudget, setRoleBudget] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [projectCategory, setProjectCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([
    "Fashion",
    "Music",
    "Film",
    "Graphic Art",
    "Photography",
    "Editing",
    "Illustration",
  ]);
  const [disableStepOne, setDisableStepOne] = useState(true);
  const [disableStepTwo, setDisableStepTwo] = useState(true);
  const [project, setProject] = useState(null);
  const [links, setLinks] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const inputRef = useRef(null);
  const [projectOwner, setProjectOwner] = useState("");
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { t } = useTranslation();

  const checkStepOne = () => {
    if (projectName && description && projectCategory && type) {
      setDisableStepOne(false);
    } else {
      setDisableStepOne(true);
    }
  };

  useEffect(() => {
    checkStepOne();
  }, [projectName, description, type, projectCategory]);

  const checkStepTwo = () => {
    if (projectDate && duration && durationQuantity && projectLocation) {
      setDisableStepTwo(false);
    } else {
      setDisableStepTwo(true);
    }
  };

  useEffect(() => {
    checkStepTwo();
  }, [projectDate, projectLocation, durationQuantity, duration]);

  const handleImages = (data) => {
    const imagePaths = data.map((item) =>
      item.content.replace("https://d71lxjj3xpfs2.cloudfront.net/", "")
    );

    // Compare the new imagePaths with the current images
    const isEqual =
      images.length === imagePaths.length &&
      images.every((value, index) => value === imagePaths[index]);

    if (!isEqual) {
      setImages(imagePaths);
    }
  };

  const refreshRoles = async () => {
    const { data, error } = await supabase.from("Roles").select();

    if (data) {
      setRoleOptions(data);
    }
  };

  const getProject = async () => {
    const { data, error } = await supabase
      .from("Projects")
      .select(
        `
          *,
          Users (first_name, last_name),
          ProjectRoles (
            *,
            Users (id,first_name, last_name)
          )
        `
      )
      .eq("id", projectId);

    if (data) {
      setProject(data[0]);
      setType(data[0].type);
      setProjectName(data[0].name);
      setDescription(data[0].description);
      setProjectDate(dayjs(data[0].data));
      setOpenDate(data[0].open_date);
      setProjectLocation(data[0].location);
      setOpenLocation(data[0].open_location);
      setDurationQuantity(data[0].duration);
      setDuration(data[0].duration_type);
      setOpenDuration(data[0].open_duration);
      setRolesArray(data[0].ProjectRoles);
      setProjectCategory(data[0].category);
      setUserRole({ name: data[0].owner_role });
      setImages(data[0].media);
    }

    if (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const fullName = `${currentUser.first_name} ${currentUser.last_name}`;
      setProjectOwner(fullName);
    }
    async function init() {
      refreshRoles();
      getProject();
    }
    init();
  }, []);

  const handleDialogClose = () => {
    setDialogValue({
      id: uuidv4(),
      name: "",
    });
    toggleOpen(false);
  };

  const handleRoleDialogClose = () => {
    setUserDialogValue({
      id: uuidv4(),
      name: "",
    });
    toggleRoleOpen(false);
  };

  const handleNewLink = () => {
    setLinks((links) => [...links, currentLink]);
  };

  const handleNewRole = () => {
    setRolesArray((rolesArray) => [
      ...rolesArray,
      {
        id: uuidv4(),
        label: currentRole.name,
        budget: roleBudget,
        filled: false,
      },
    ]);
    setCurrentRole(null);
    setRoleBudget(0);
  };

  const handleRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        id: uuidv4(),
        name: dialogValue.name,
      },
    ]);
    setCurrentRole(dialogValue);
    handleDialogClose();
  };

  const handleUserRoleSubmit = (event) => {
    event.preventDefault();
    setRoleOptions((roleOptions) => [
      ...roleOptions,
      {
        id: uuidv4(),
        name: userDialogValue.name,
      },
    ]);
    setUserRole(userDialogValue);
    handleRoleDialogClose();
  };

  const handleDelete = (id) => {
    setRolesArray(rolesArray.filter((role) => role.id !== id));
  };

  const handleLinkDelete = (link) => {
    setLinks(links.filter((item) => item !== link));
  };

  const handleCoordinates = async () => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      projectLocation
    )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.status !== "OK" || !data.results || data.results.length === 0) {
        throw new Error("Geocoding failed");
      }
      setCoordinates(data.results[0].geometry.location);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewProject = async () => {
    setShowProgress(true);
    try {
      const projectId = uuidv4();
      const mediaUrls = await handleUpload({
        images,
        project: projectId,
      }).catch(console.error);

      const projectData = {
        name: projectName,
        description: description,
        date: projectDate.$d,
        open_date: openDate,
        location: projectLocation,
        coordinates,
        open_location: openLocation,
        duration: durationQuantity,
        duration_type: duration,
        open_duration: openDuration,
        owner_role: userRole.name,
        owner: currentUser.sub,
        id: projectId,
        media: mediaUrls,
        category: projectCategory,
        type,
      };

      // Insert project data
      const { error: projectError } = await supabase
        .from("Projects")
        .update(projectData)
        .eq("id", projectId);

      if (projectError) {
        console.error("Error updating project:", projectError);
      } else {
        // Prepare roles data for batch insertion
        const rolesData = rolesArray.map((role) => ({
          project_id: projectId,
          name: role.label,
          filled: false,
          ...(role.budget !== undefined &&
            role.budget !== null && { budget: role.budget }),
        }));

        // Adding an extra item for project owner
        rolesData.push({
          project_id: projectId,
          name: userRole.name,
          user: currentUser.sub,
          filled: true,
        });

        // Batch insert roles data
        const { error: rolesError } = await supabase
          .from("ProjectRoles")
          .insert(rolesData);

        if (rolesError) {
          console.error("Error inserting project roles:", rolesError);
        } else {
          setShowProgress(false);
          navigate(`/project/${projectId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (step !== 2) return;

    const populateAddress = () => {
      if (inputRef.current) {
        inputRef.current.value = projectLocation; // Set the input value to the address
      }
    };

    // Initialize Google Maps Places Autocomplete
    const initializeAutocomplete = () => {
      if (!inputRef.current) {
        console.error("Input reference not found");
        return;
      }
      try {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place.formatted_address && !place.name) {
            console.error(
              "No formatted_address or name found in the selected place."
            );
            return;
          }
          inputRef.current.value = place.formatted_address || place.name;
          setProjectLocation(place.formatted_address || place.name);
        });
      } catch (error) {
        console.error("Error initializing autocomplete:", error);
      }
    };

    if (projectLocation) {
      // Populate the address if available
      populateAddress();
    } else if (
      window.google &&
      window.google.maps &&
      window.google.maps.places
    ) {
      initializeAutocomplete();
    } else {
      window.initializeGooglePlacesAutocomplete = initializeAutocomplete;
    }
  }, [step, projectLocation]);

  const handleChange = (event) => {
    setDuration(event.target.value);
  };

  const handleAddItem = (event) => {
    event.stopPropagation();
    toggleOpen(true);
  };

  const handleUserRoleItem = (event) => {
    event.stopPropagation();
    toggleRoleOpen(true);
  };

  const truncateLabel = (label) => {
    return label.length > 10 ? label.substring(0, 10) + "..." : label;
  };

  const openLinkInNewTab = (url) => {
    if (url) {
      // Open the URL in a new tab
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const CustomOption = styled("li")(({ theme }) => ({
    [`&.${autocompleteClasses.option}`]: {
      justifyContent: "left",
      backgroundColor: "#f9f9f9",
    },
  }));

  return (
    <Box
      className="divider-shadow"
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: "#ffffff",
        paddingBottom: "10px",
        paddingTop: "20px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1000px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "left",
          }}
          className="create-project"
        >
          <div className="stepper-container">
            <Avatar
              sx={{
                backgroundColor: step === 1 && "#9f7fe3",
                width: 30,
                height: 30,
              }}
            >
              <DescriptionIcon sx={{ width: 15, height: 15 }} />
            </Avatar>
            <div className="divider"></div>
            <Avatar
              sx={{
                backgroundColor: step === 2 && "#9f7fe3",
                width: 30,
                height: 30,
              }}
            >
              <LocationOnIcon sx={{ width: 15, height: 15 }} />
            </Avatar>
            <div className="divider"></div>
            <Avatar
              sx={{
                backgroundColor: step === 3 && "#9f7fe3",
                width: 30,
                height: 30,
              }}
            >
              <GroupsIcon sx={{ width: 15, height: 15 }} />
            </Avatar>
            <div className="divider"></div>
            <Avatar
              sx={{
                backgroundColor: step === 4 && "#9f7fe3",
                width: 30,
                height: 30,
              }}
            >
              <ImageIcon sx={{ width: 15, height: 15 }} />
            </Avatar>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "40px",
            }}
          >
            {step === 1 && (
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  {" "}
                  <div className="section">{t("project")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <div className="title">{t("projectTitle")}</div>
                  <TextField
                    sx={{ width: "100%" }}
                    variant="outlined"
                    value={projectName || ""}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                  <div className="title">{t("projectType")}</div>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    sx={{ width: "100%", padding: "0px !important" }}
                  >
                    <MenuItem value={"collab"}>{t("collab")}</MenuItem>
                    <MenuItem value={"paid"}>{t("paid")}</MenuItem>
                  </Select>
                  <div className="title">{t("projectCategory")}</div>

                  <Autocomplete
                    freeSolo
                    value={projectCategory}
                    onChange={(event, newValue) => {
                      setProjectCategory(newValue);
                    }}
                    options={categoryOptions.map((option) => option)}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <div className="title">{t("description")}</div>
                  {/* <ReactQuill
                  theme="snow"
                  modules={modules}
                  value={description}
                  onChange={setDescription}
                  style={{ height: "100px" }}
                /> */}
                  <TextField
                    sx={{ width: "100%" }}
                    variant="outlined"
                    multiline
                    rows={6}
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "5px",
                  }}
                >
                  <Button
                    onClick={() => setStep(2)}
                    disabled={disableStepOne}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  {" "}
                  <div className="section">{t("when")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div className="title">{t("date")}</div>
                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openDate}
                            onChange={(event) =>
                              setOpenDate(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#564a92",
                              "&.Mui-checked": {
                                color: "#564a92",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                              fontFamily: "Epilogue",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={projectDate}
                      onChange={(newValue) => {
                        setProjectDate(newValue);
                      }}
                      sx={{ paddingRight: "20px", width: "100%" }}
                    />
                  </LocalizationProvider>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div className="title">{t("location")}</div>

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openLocation}
                            onChange={(event) =>
                              setOpenLocation(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#564a92",
                              "&.Mui-checked": {
                                color: "#564a92",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                              fontFamily: "Epilogue",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <TextField
                    inputRef={inputRef}
                    placeholder={t("address")}
                    variant="outlined"
                    sx={{ width: "100%" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div className="title">{t("duration")}</div>

                    <FormGroup
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{ marginRight: "5px" }}
                        control={
                          <Checkbox
                            checked={openDuration}
                            onChange={(event) =>
                              setOpenDuration(event.target.checked)
                            }
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 16 },
                              color: "#564a92",
                              "&.Mui-checked": {
                                color: "#564a92",
                              },
                            }}
                          />
                        }
                        label={t("open")}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontWeight: "300",
                              fontSize: "12px",
                              fontFamily: "Epilogue",
                            },
                          },
                        }}
                      />
                      <Tooltip title={t("openTooltip")} placement="right">
                        <InfoIcon sx={{ color: "#564a92", fontSize: "16px" }} />
                      </Tooltip>
                    </FormGroup>
                  </Box>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        id="outlined-number"
                        placeholder="3"
                        type="number"
                        sx={{ marginRight: "20px", width: "100%" }}
                        value={durationQuantity}
                        onChange={(e) => setDurationQuantity(e.target.value)}
                      />
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={duration}
                          onChange={handleChange}
                        >
                          <MenuItem value={"Hours"}>{t("hours")}</MenuItem>
                          <MenuItem value={"Days"}>{t("days")}</MenuItem>
                          <MenuItem value={"Weeks"}>{t("weeks")}</MenuItem>
                          <MenuItem value={"Months"}>{t("months")}</MenuItem>
                          <MenuItem value={"Years"}>{t("years")}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setStep(3);
                      handleCoordinates();
                    }}
                    disabled={disableStepTwo}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                  <Button
                    onClick={() => setStep(1)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 3 && (
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  <div className="section">{t("lead")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <div className="title">{t("name")}</div>
                  <TextField
                    sx={{ width: "100%" }}
                    variant="outlined"
                    value={projectOwner || ""}
                    onChange={(e) => setProjectOwner(e.target.value)}
                  />
                  <div className="title">{t("role")}</div>

                  <Autocomplete
                    disablePortal
                    value={userRole}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setUserRole(newValue);
                    }}
                    id="combo-box-demo"
                    options={[
                      { RoleName: "Add new item", specialOption: true },
                      ...roleOptions,
                    ]}
                    getOptionLabel={(option) => option.name || ""}
                    sx={{ width: "100%" }}
                    renderOption={(props, option) => {
                      if (option.specialOption) {
                        return (
                          <CustomOption {...props}>
                            <Button
                              sx={{ color: "#564a92" }}
                              startIcon={<AddCircleIcon />}
                              onClick={(event) => handleUserRoleItem(event)}
                            >
                              Add New Role
                            </Button>
                          </CustomOption>
                        );
                      }
                      return (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Choose Role" />
                    )}
                  />
                  <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openRoleDialog}
                    onClose={handleRoleDialogClose}
                    className="custom-dialog"
                  >
                    <DialogTitle>Add a new role</DialogTitle>
                    <DialogContent>
                      <DialogContentText sx={{ marginBottom: "20px" }}>
                        Did we miss any? Please, add it!
                      </DialogContentText>
                      <TextField
                        autoFocus
                        value={userDialogValue.name}
                        onChange={(event) =>
                          setUserDialogValue({
                            ...userDialogValue,
                            name: event.target.value,
                          })
                        }
                        placeholder="Enter Role"
                        type="text"
                        variant="outlined"
                        fullWidth
                      />
                    </DialogContent>
                    <DialogActions sx={{ padding: "24px" }}>
                      <Button
                        className="dialog-btn"
                        variant="contained"
                        onClick={handleRoleDialogClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="dialog-btn"
                        onClick={handleUserRoleSubmit}
                      >
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className="section">{t("who")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={type === "paid" ? 6 : 11}>
                      <div className="title">{t("roles")}</div>
                      <Autocomplete
                        disablePortal
                        value={currentRole}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          setCurrentRole(newValue);
                        }}
                        options={[
                          { name: "Add new item", specialOption: true },
                          ...roleOptions,
                        ]}
                        getOptionLabel={(option) => option.name || ""}
                        sx={{ width: "100%" }}
                        renderOption={(props, option) => {
                          if (option.specialOption) {
                            return (
                              <CustomOption {...props}>
                                <Button
                                  sx={{ color: "#564a92" }}
                                  startIcon={<AddCircleIcon />}
                                  onClick={(event) => handleAddItem(event)}
                                >
                                  Add New Role
                                </Button>
                              </CustomOption>
                            );
                          }
                          return (
                            <Box component="li" {...props}>
                              {option.name}
                            </Box>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Add Role" />
                        )}
                      />
                      <Dialog
                        fullWidth={true}
                        maxWidth={"sm"}
                        open={openDialog}
                        onClose={handleDialogClose}
                        className="custom-dialog"
                      >
                        <DialogTitle>Add a new role</DialogTitle>
                        <DialogContent>
                          <DialogContentText sx={{ marginBottom: "20px" }}>
                            Did we miss any? Please, add it!
                          </DialogContentText>
                          <TextField
                            autoFocus
                            value={userDialogValue.name}
                            onChange={(event) =>
                              setUserDialogValue({
                                ...userDialogValue,
                                name: event.target.value,
                              })
                            }
                            placeholder="Enter Role"
                            type="text"
                            variant="outlined"
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions sx={{ padding: "24px" }}>
                          <Button
                            className="dialog-btn"
                            variant="contained"
                            onClick={handleDialogClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="dialog-btn"
                            onClick={handleUserRoleSubmit}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    {type === "paid" && (
                      <Grid item xs={5}>
                        <div className="title">{t("budget")}</div>
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          label="Amount"
                          fullWidth
                          type="number"
                          value={roleBudget}
                          onChange={(e) => setRoleBudget(e.target.value)}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton onClick={handleNewRole} aria-label="add role">
                        <img alt="add icon" src="/images/add_icon.svg"></img>
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "8px",
                      marginTop: "15px",
                    }}
                  >
                    {rolesArray &&
                      rolesArray.map((role) =>
                        role.user !== currentUser.sub ? (
                          <Chip
                            key={role.id}
                            label={
                              type === "paid"
                                ? `${role.label} - $${role.budget}`
                                : role.label
                            }
                            className="role"
                            onDelete={() => handleDelete(role.id)}
                            sx={{
                              "& .MuiChip-deleteIcon": {
                                color: "white",
                              },
                              "& .MuiChip-deleteIcon:hover": {
                                color: "#ffffffc7",
                              },
                            }}
                          />
                        ) : null
                      )}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                  }}
                >
                  <Button
                    onClick={() => setStep(4)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("next")}
                  </Button>
                  <Button
                    onClick={() => setStep(2)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {step === 4 && (
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  <div className="section">{t("images")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <SortableTool
                    sendImages={handleImages}
                    initialData={images}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <div className="section">{t("links")}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <div className="title">{t("moodboard")}</div>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      multiple
                      defaultValue={[]}
                      options={[]}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          paddingRight: "40px !important",
                        },
                        "& .MuiAutocomplete-input": {
                          paddingRight: "10px !important",
                        },
                      }}
                      freeSolo
                      value={links}
                      onChange={(event, newValue) => {
                        setLinks(newValue);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            className="link-chip"
                            label={truncateLabel(option)}
                            sx={{
                              fontFamily: "Epilogue",
                              fontSize: "12px",
                              borderRadius: "5px",
                              background: "#fff",
                              border: "1px solid #b3b3b3",
                            }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Add Link" />
                      )}
                    />
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "flex-start",
                      gap: "5px",
                      flexWrap: "wrap",
                      marginTop: "10px",
                    }}
                  >
                    {links &&
                      links.map((link) =>
                        ReactPlayer.canPlay(link) ? (
                          <div
                            style={{
                              borderRadius: "5px",
                              width: "100px",
                              height: "80px",
                            }}
                          >
                            {" "}
                            <ReactPlayer
                              url={link}
                              width="100%"
                              height="100%"
                              playing={false}
                              muted={true}
                              controls={false}
                              className="react-player"
                            />
                          </div>
                        ) : (
                          <div
                            className="link-container"
                            onClick={() => openLinkInNewTab(link)}
                          >
                            <LinkIcon></LinkIcon>
                          </div>
                        )
                      )}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    gap: "20px",
                  }}
                >
                  <Button
                    onClick={() => handleNewProject()}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("saveProject")}
                  </Button>
                  <Button
                    onClick={() => setStep(3)}
                    className="create-btn"
                    variant="contained"
                  >
                    {t("back")}
                  </Button>
                </Grid>
              </Grid>
            )}
            {showProgress && (
              <LinearProgress sx={{ marginTop: "15px" }} color="secondary" />
            )}
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default EditProject;
